<template>
  <interior-page-content-container
    nav-selection=""
    @back-click="$router.go(-1)"
    :show-bottom-nav="false"
    :show-back-button-top-left="true"
    :show-back-button-in-nav="true"
    :showRightControls="false"
    backButtonText="Project"
    :loading="loading"
  >
    <template #title>
      {{item.title}}
    </template>

    <template #content>
      <div class="`
        flex flex-col items-center self-center h-full
        w-72 pt-4 md:w-80 lg:w-96 max-w-md
        flex-shrink-0
        pb-5
      `">

        <project-timeline-date
          size="large"
          :date="item.date"
        />

        <div class="w-full p-3 mb-4 rounded-lg bg-backfill">
          <text-display-component
            :value="date"
            label="Start Date"
          />
          <text-input-component
            @save="triggerUpdate"
            :value.sync="item.duration"
            :valid="true"
            label="Duration"
            type="number"
            mode="edit"
          />
        </div>

        <div class="w-full p-3 mb-4 rounded-lg bg-backfill">
          <textarea-component
            @save="triggerUpdate"
            :value.sync="item.notes"
            :valid="true"
            label="Notes"
            mode="edit2"
            :rows="4"
          />
        </div>

        <div class="opacity-0">space...</div>
      </div>
    </template>

  </interior-page-content-container>
</template>

<script>
import { mapProjectFields } from '@/store/mappers';
import { PROJECT_GET, PROJECT_PUT } from '@/store/actions';

import moment from 'moment';

import TextInputComponent from '@/components/shared/inputs/TextInputComponent.vue';
import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import ProjectTimelineDate from '@/components/projects/timeline/ProjectTimelineDate.vue';
import TextDisplayComponent from '@/components/shared/general/TextDisplayComponent.vue';
import TextareaComponent from '@/components/shared/inputs/TextareaComponent.vue';

export default {
  name: 'ProjectTimelineEditItem',
  components: {
    TextInputComponent,
    InteriorPageContentContainer,
    ProjectTimelineDate,
    TextDisplayComponent,
    TextareaComponent,
  },
  data() {
    return {
      loading: true,
      valx: null,
      val: '123',
      item: {
        duration: '',
        date: '',
        title: '',
        notes: '',
      },
    };
  },
  computed: {
    ...mapProjectFields({
      timeline: 'project.timeline',
      itemList: 'project.timeline.to_do',
      startDate: 'project.timeline.start_date',
    }),
    date() {
      return moment(this.item.date).format('D MMMM, YYYY');
    },
  },
  created() {
    this.$store.dispatch(PROJECT_GET, this.$route.params.project_id)
      .then((project) => {
        this.initializeItem(project);
      })
      .catch(() => this.$router.push({
        name: 'SpecificPageNotFound',
        params: { page: 'project' },
      }))
      .finally(() => { this.loading = false; });
  },
  methods: {
    initializeItem(project) {
      const date = moment(project.timeline.start_date);

      this.item = project.timeline.to_do.reduce((acc, _item) => {
        const result = {
          ..._item,
          duration: _item.duration.toString(),
          date: date.toString(),
        };
        date.add(result.duration, 'day');

        if (this.$route.params.item_id.toString() === result.id.toString()) {
          return result;
        }
        return acc;
      }, this.item);
    },
    triggerUpdate() {
      this.itemList = this.itemList.map((item) => {
        if (item.id === this.item.id) return this.item;
        return item;
      });

      this.$store.dispatch(PROJECT_PUT, {
        projectId: this.$route.params.project_id,
        project: {
          timeline: this.timeline,
        },
      });
    },
  },
};
</script>
